<template>
  <el-container>
    <el-header style="display: flex; align-items: center">
      <div
          style="
          display: flex;
          justify-content: flex-start;
          cursor: default;
          align-items: center;
        "
      >
        <span @click="$router.back()">全部考试 > </span
        ><span style="color: #563279">{{ examName }}</span>
      </div>
    </el-header>
    <el-main style="padding-top: 5px">
      <div style="display: flex; align-items: center; width: 100%">
        <div
            style="color: #333333; font-size: 20px; font-weight: bold; flex: 1"
        >
          {{ examName }}
        </div>
        <div style="flex: 2"></div>
      </div>
      <el-table
          :data="subjectList"
          stripe
          style="width: 100%; margin-top: 10px"
      >
        <el-table-column
            label="全选"
            type="selection"
            width="55"
            align="center"
        >
        </el-table-column>
        <el-table-column label="科目名称" width="200" align="center">
          <template slot-scope="scope">
            <img
                src="@/assets/image/file.png"
                @click="
                gotomonitorgroup(scope.row.subjectUuid, scope.row.subjectName)
              "
            />
            <span style="margin-left: 10px">{{ scope.row.subjectName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="开始时间" align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ toFormatString(scope.row.beginTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="结束时间" align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ toFormatString(scope.row.endTime) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>

<script>
import {getSubjectListInfo} from "r/admin/videorecord"

export default {
  data() {
    return {
      subjectList: [],
      examId: "",
      examName: "",
    };
  },
  created() {
    this.examName = window.localStorage.getItem("examName")
    this.examId = window.localStorage.getItem("examId")
    // if (this.$route.query.examName) {
    //   this.examName = this.$route.query.examName;
    //   this.examId = this.$route.query.examId;
    //   //缓存名单
    //   window.localStorage.setItem("examName", this.examName);
    // } else if (window.localStorage.getItem("examName")) {
    //   this.examName = window.localStorage.getItem("examName");
    // }
    this.getsubjectlist();
  },
  computed: {
    // 时间转化
    toFormatString() {
      return  function (data) {
        let dateObj = new Date(data);
        let month = dateObj.getMonth() + 1;
        let day =  dateObj.getDate();
        let hours = dateObj.getHours();
        let minutes = dateObj.getMinutes();
        let seconds = dateObj.getSeconds();
        if(month<10){
          month = "0" + month;
        }
        if(day<10){
          day = "0" + day;
        }
        if(hours<10){
          hours = "0" + hours;
        }
        if(minutes<10){
          minutes = "0" + minutes;
        }
        if(seconds<10){
          seconds = "0" + seconds;
        }
        let UnixTimeToDate = dateObj.getFullYear() + '-' + month + '-' +day + ' ' + hours + ':' + minutes + ':' + seconds;
        return UnixTimeToDate;
      }
    }
  },
  methods: {
    //获取科目列表
    async getsubjectlist() {
      getSubjectListInfo({
        examId: this.examId
      }).then(res => {
        this.subjectList = res.data.data
      })
    },
    gotomonitorgroup(subjectUuid, subjectName) {
      window.localStorage.setItem("subjectName",subjectName)
      window.localStorage.setItem("subjectUuid",subjectUuid)
      this.$router.push({
        name: "admin-videorecord-monitorgroup",
        query: {
          subjectUuid: subjectUuid,
          subjectName: subjectName,
        },
      });
    }
  },
};
</script>

<style scoped>
/* 去除表格的内边框 */
::v-deep .el-table__row > td {
  border: none;
}

/* 去除最下面的边框线 */
::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #563279 !important;
  border: 1px solid #563279;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner:hover {
  border: none;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #563279 !important;
  border: 1px solid #563279;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #563279;
  border-radius: 40px;
}

::v-deep .el-pagination.is-background .el-pager li {
  border-radius: 40px;
}

::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #dcdfe6;
  border-color: #fff;
}

::v-deep .el-checkbox__inner {
  border: none;
  background: #dcdfe6;
}

::v-deep .el-input__inner {
  border: none;
  background: #fafafa;
}
</style>